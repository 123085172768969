<template>
  <b-navbar
    class="bg-blue-dark-600 px-1 px-md-3 py-1 w-100 sticky-top"
    variant="faded"
    type="light"
  >
    <b-navbar-brand class="clickable mr-0 mr-md-3 py-0">
      <img
        alt="Domi"
        :src="$appLogo"
        style="margin: -10px 10px 0 10px; height: 25px;object-fit: contain;"
      />
    </b-navbar-brand>
    <b-icon
      v-b-toggle.main-side-menu
      class="clickable"
      icon="list"
      variant="white"
      font-scale="1.6"
    ></b-icon>
    <b-navbar-nav v-if="hasAdminFilter && isLargeScreen" class="ml-auto">
      <b-nav-item>
        <menu-filter />
      </b-nav-item>
    </b-navbar-nav>
    <b-navbar-nav class="ml-auto">
      <b-nav-item v-if="isLargeScreen">
        <user-menu-info
          :actions="profileActions"
          :avatar="userAvatar"
          :fullName="userFullName"
        />
      </b-nav-item>
      <b-nav-item v-if="$_authUser.type !== 'domi'">
        <menu-notifications />
      </b-nav-item>
      <b-nav-item>
        <language-switcher />
      </b-nav-item>
    </b-navbar-nav>
  </b-navbar>
</template>

<script>
import { appMixin, authMixin, userMixin } from "./../../mixins";

import LanguageSwitcher from "./BaseLanguageSwitcher";
import MenuNotifications from "./MenuNotifications";
import UserMenuInfo from "./UserMenuInfo";
import MenuFilter from "./MenuFilter";
export default {
  components: {
    LanguageSwitcher,
    MenuNotifications,
    UserMenuInfo,
    MenuFilter
  },
  props: {
    isLargeScreen: {
      type: Boolean,
      default: false
    }
  },
  mixins: [authMixin, userMixin, appMixin],
  data() {
    return {
      profileActions: [
        { text: "button.userProfile", callbackFunc: this.goToUserAccountPage },
        { text: "button.logout", callbackFunc: this.logout }
      ]
    };
  },
  computed: {
    userFullName() {
      return this.$_getUserFullName(this.$_authUser.profile);
    },
    userAvatar() {
      return this.$_getUserProfilePhoto(this.$_authUser.profile);
    },
    hasAdminFilter() {
      const pages = [
        "Dashboard",
        "Issues",
        "Communications",
        "Condominiums",
        "Maintainers"
      ];

      if (this.$is1Board) {
        pages.push("MaintainerUsers");
        pages.push("Posts");
        pages.push("Parameters");
        pages.push("Groups");
      }
      return this.$_authUserIsDomi && pages.includes(this.$route.name);
    }
  },
  methods: {
    logout() {
      this.$store.dispatch("logout", null, { root: true });
    },
    goToUserAccountPage() {
      if (this.$route.name !== "UserAccount")
        this.$router.push({ name: "UserAccount" });
    }
  }
};
</script>

<style lang="scss" scoped>
.navbar {
  height: var(--top-menu-height);
  z-index: var(--top-menu-z-index);
}
@media screen and (max-width: 959px) {
  .nav-item {
    /deep/ {
      .btn.dropdown-toggle {
        padding: 0;
      }
    }
  }
}
</style>
