<template>
  <b-modal
    v-model="_state"
    size="lg"
    centered
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @show="initForm"
  >
    <template v-slot:modal-title>
      <h5 class="mb-0 text-secondary">
        {{ $t("page.condominium.editUser") }} -
        <b-badge
          class="text-white font-weight-normal"
          :variant="userStatusVariant[user.status]"
        >
          {{ $t(user.status) }}
        </b-badge>
      </h5>
    </template>
    <template v-slot:default>
      <validation-observer
        v-if="tab === 'form'"
        ref="observer"
        v-slot="{ invalid }"
      >
        <b-form>
          <b-row class="no-gutters ml-n3">
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.name"
                label="requiredField.name"
                veeName="name"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input
                v-model="user.surname"
                label="requiredField.surname"
                veeName="surname"
                :veeRules="{ required: true }"
              />
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-4 pl-3">
              <form-input v-model="user.email" label="email" :disabled="true" />
            </b-col>
            <b-col class="col-12 col-md-6 col-lg-4 pl-3">
              <form-input v-model="user.telephone" label="telephone" />
            </b-col>
            <b-col v-if="$is1Board" class="col-12 col-md-6 pl-3">
              <label class="font-bold"><b>LinkedIn</b></label>
              <div
                v-if="user.linkedIn"
                style="max-width: 95%; overflow-x: hidden; text-overflow: ellipsis"
              >
                <a target="_blank" :href="user.linkedIn">{{ user.linkedIn }}</a>
              </div>
              <div v-else>{{ $t("noInfo") }}</div>
            </b-col>
            <b-col class="col-12 col-md-6 pl-3" v-if="$is1Board">
              <form-text-area readonly v-model="user.bio" label="Bio" />
            </b-col>
            <b-col class="col-12 col-lg-4 pl-3">
              <form-input
                v-if="$is1Board"
                v-model="user.role"
                label="requiredField.roleInCondominium"
                veeName="administrator"
                :veeRules="{ required: true }"
              />
              <form-select v-else v-model="user.role" label="roleInCondominium">
                <b-form-select-option
                  v-for="(role, index) in userRoleOptions"
                  :key="index"
                  :value="role"
                >
                  {{ $t(`userRoles.${role}`) }}
                </b-form-select-option>
              </form-select>
            </b-col>
            <div class="w-100 ml-3 my-4" />
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.address" label="address" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.city" label="city" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.province" label="province" />
            </b-col>
            <b-col class="col-12 col-md-6 pl-3">
              <form-input v-model="user.zip" label="cap" />
            </b-col>
            <template v-if="$is1Board">
              <b-col class="col-12 col-md-6 pl-3">
                <strong>{{ $t("page.groups.title") }}</strong>
                <div v-if="userGroups && userGroups.length > 0">
                  <div v-for="(group, index) in userGroups" :key="index">
                    {{ group.groupName }} [{{ group.userRole }}]
                  </div>
                </div>
                <div v-else>-</div>
              </b-col>
              <b-col class="col-12 col-md-6 pl-3">
                <base-switcher
                  v-model="insertionPermission"
                  name="contactInsertion"
                  switchLabel="page.condominium.contact-insertion"
                  sync
                />
              </b-col>
            </template>
            <b-col class="col-12 pl-3 switch-col" v-if="$is1Board">
              <base-switcher
                v-model="user.supplierEditting"
                name="contactInsertion"
                switchLabel="page.condominium.contact-insertion"
                sync
              />
              <base-switcher
                v-model="user.eventCreation"
                name="eventCreation"
                switchLabel="page.condominium.event-creation"
                sync
              />
            </b-col>
            <b-col class="col-5 pl-3 pt-3" v-if="$is1Board">
              <b-row no-gutters class="align-items-center">
                <strong>{{ $t("page.groups.associatedGroups") }}</strong>
              </b-row>
              <group-management
                ref="groups"
                :userId="user._id"
                :joinedGroups="userGroups"
              />
            </b-col>
          </b-row>
        </b-form>
        <hr class="my-3 mx-n3" />
        <b-row class="no-gutters justify-content-end">
          <b-button
            variant="outline-dark"
            class="mr-1"
            @click="setModalState(false)"
          >
            {{ $t("button.cancel") }}
          </b-button>
          <b-button
            class="text-white"
            variant="primary"
            :disabled="invalid"
            @click="updateUser"
          >
            {{ $t("button.saveChanges") }}
          </b-button>
        </b-row>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import { appMixin, authMixin } from "./../../mixins";

import FormInput from "../Base/BaseFormInput";
import FormSelect from "../Base/BaseFormSelect";
import FormTextArea from "../Base/BaseFormTextarea";
import BaseSwitcher from "./../Base/Switcher.vue";
import GroupManagement from "../Groups/UserGroupManagement.vue";
export default {
  components: {
    FormInput,
    FormSelect,
    FormTextArea,
    BaseSwitcher,
    GroupManagement
  },
  props: {
    state: {
      type: Boolean,
      default: false
    }
  },
  mixins: [appMixin, authMixin],
  data() {
    return {
      tab: "form",
      user: {},
      userGroups: [],
      userRoleOptions: ["doorman", "director", "owner", "tenant"],
      userStatusVariant: {
        active: "green",
        notverified: "cyan",
        invited: "yellow"
      }
    };
  },
  computed: {
    _state: {
      get() {
        return this.state;
      },
      set(value) {
        this.setModalState(value);
      }
    },
    currentUser() {
      return this.$store.getters["user/getCurrentUser"];
    },
    currentCondominium() {
      return this.$store.getters["condominium/getCurrentCondominium"];
    }
  },
  methods: {
    async initForm() {
      await this.$store.dispatch(
        "consumer/retrieveConsumerUser",
        this.currentUser._id
      );
      this.user = {
        _id: this.currentUser._id,
        status: this.currentUser.status,
        name: "",
        surname: "",
        email: "",
        telephone: "",
        address: "",
        city: "",
        province: "",
        zip: "",
        supplierEditting: this.currentUser.supplierEditting,
        role: this.getUserRole(),
        eventCreation: this.currentUser.eventCreation
      };
      if (this.currentUser.profile) {
        this.user.name = this.currentUser.profile.name || "";
        this.user.surname = this.currentUser.profile.surname || "";
        this.user.telephone = this.currentUser.profile.telephone || "";
        this.user.address = this.currentUser.profile.address || "";
        this.user.city = this.currentUser.profile.city || "";
        this.user.province = this.currentUser.profile.province || "";
        this.user.zip = this.currentUser.profile.zip || "";
        this.user.linkedIn = this.currentUser.profile.linkedIn || "";
        this.user.bio = this.currentUser.profile.bio || "";
        this.user.emailPublic = this.currentUser.profile.emailPublic || false;
        this.user.telephonePublic =
          this.currentUser.profile.telephonePublic || false;
      }
      if (this.currentUser.type !== "guest")
        this.user.email = this.currentUser.email || "";
      this.userGroups = this.$store.getters[
        "consumer/getCurrentConsumerUserGroups"
      ];
    },
    getUserRole() {
      if (this.currentUser.condominiums) {
        if (this.$_authUser.type !== "maintainer") {
          const condominium = this.currentUser.condominiums.find(
            c => c.condominiumId === this.currentCondominium._id
          );
          return condominium.role || "";
        } else {
          return this.currentUser.condominiums[0].role || "";
        }
      } else return "";
    },
    async updateUser() {
      try {
        this.$store.commit("loader/SET_LOADER", {});

        await this.$store.dispatch("user/updateUser", {
          userId: this.user._id,
          userInfo: this.getProfilePayload()
        });

        if (this.user.role !== this.getUserRole())
          await this.$store.dispatch("user/updateUserCondominium", {
            userId: this.user._id,
            condominiumId: this.currentCondominium._id,
            payload: {
              role: this.user.role
            }
          });

        await this.$store.dispatch("condominium/retrieveCondominiumUsers");
        await this.$refs.groups?.saveChanges();
        this.setModalState(false);
      } catch (error) {
        console.log(error);
      } finally {
        this.$store.commit("loader/RESET");
      }
    },
    getProfilePayload() {
      const userPayload = {
        name: this.user.name,
        surname: this.user.surname,
        email: this.user.email,
        telephone: this.user.telephone,
        address: this.user.address,
        city: this.user.city,
        province: this.user.province,
        zip: this.user.zip
      };
      if (this.$is1Board) {
        userPayload.bio = this.user.bio;
        userPayload.linkedIn = this.user.linkedIn;
        userPayload.emailPublic = this.currentUser.profile.emailPublic;
        userPayload.telephonePublic = this.currentUser.profile.telephonePublic;
        userPayload.supplierEditting = this.user.supplierEditting;
        userPayload.eventCreation = this.user.eventCreation;
      }

      return userPayload;
    },
    setModalState(value) {
      this.$emit("update:state", value);
    }
  }
};
</script>

<style lang="scss" scoped>
.switch-col {
  /deep/ .d-flex {
    width: 100%;
    max-width: 310px;
    justify-content: space-between;
  }
}
</style>
