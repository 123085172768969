/* eslint prefer-promise-reject-errors: ["error", {"allowEmptyReject": true}] */
import services from "./../services";
import { SET_ERROR } from "./../errorHandler";
import { pagination } from "../constants/pagination";

const getDefaultState = () => {
  return {
    issues: [],
    currentIssue: {},
    filters: {
      type: [],
      status: [],
      condominium: null,
      reported: false
    },
    typeOptions: [
      "electric",
      "hydraulic",
      "tv",
      "lift",
      "automation",
      "intercom",
      "telephone",
      "cleaning",
      "waste",
      "disinfestation",
      "green",
      "other"
    ],
    statusOptions: [
      "new",
      "incomplete",
      "assigned-maintainer",
      "assigned-administrator",
      "maintainer-closed",
      "closed",
      "suspended",
      "deleted"
    ],
    sortBy: "createdAt",
    sortDesc: true,
    availableMaintainers: []
  };
};
const state = getDefaultState();

const getters = {
  getIssues: state => state.issues,
  getCurrentIssue: state => state.currentIssue,

  getTypeOptions: state => state.typeOptions,
  getStatusOptions: state => state.statusOptions,

  getFilters: state => state.filters,
  getFilterTypes: state => state.filters.type,
  getFilterStatus: state => state.filters.status,
  getFilterCondominium: state => state.filters.condominium,
  getFilterReported: state => state.filters.reported,

  getSortBy: state => state.sortBy,
  getSortDesc: state => state.sortDesc,

  getAvailableMaintainers: state => state.availableMaintainers
};

const mutations = {
  RESET(state) {
    const s = getDefaultState();
    Object.keys(s).forEach(key => {
      state[key] = s[key];
    });
  },
  SET_ISSUES(state, issues) {
    state.issues = issues;
  },
  SET_CURRENT_ISSUE(state, issue) {
    state.currentIssue = issue;
  },
  SET_FILTERS(state, filters) {
    state.filters = filters;
  },
  SET_FILTER_TYPES(state, type) {
    state.filters.type = type;
  },
  SET_FILTER_STATUS(state, status) {
    state.filters.status = status;
  },
  SET_FILTER_CONDOMINIUM(state, condominium) {
    state.filters.condominium = condominium;
  },
  SET_FILTER_REPORTED(state, reported) {
    state.filters.reported = reported;
  },
  SET_SORT_BY(state, sortBy) {
    state.sortBy = sortBy;
  },
  SET_SORT_DESC(state, sortDesc) {
    state.sortDesc = sortDesc;
  },
  SET_AVAILABLE_MAINTAINERS(state, maintainers) {
    state.availableMaintainers = maintainers;
  }
};

const actions = {
  async retrieveUserIssues(
    { commit, getters, rootGetters },
    { initial, hasPagination } = {}
  ) {
    try {
      const issues = getters.getIssues || [];
      const parameters = {
        type: getters.getFilterTypes.join(","),
        status: getters.getFilterStatus.join(","),
        skip: hasPagination
          ? initial
            ? pagination.skip
            : issues
            ? issues.length
            : 0
          : pagination.skip,
        limit: hasPagination ? pagination.limit : -1,
        sort: `${getters.getSortBy} ${getters.getSortDesc ? "asc" : "desc"}`
      };
      if (getters.getFilterCondominium) {
        parameters.condominiumId = getters.getFilterCondominium;
      }
      if (getters.getFilterReported) {
        parameters.isReported = getters.getFilterReported;
      }
      if (
        rootGetters["auth/isDomiUser"] &&
        rootGetters["filter/getAdministratorSelected"] !== null
      ) {
        parameters.administratorId =
          rootGetters["filter/getAdministratorSelected"]._id;
      }

      const response = await services.IssueService.retrieveUserIssues(
        parameters
      );
      commit(
        "SET_ISSUES",
        initial || !hasPagination
          ? response
          : [...(getters.getIssues || []), ...response]
      );
    } catch (error) {
      let messageText = null;
      if (
        error.response?.body?.code === 404 &&
        error.response?.body?.errorCode === 763
      )
        messageText = "page.issue.noCondominiumErrorMsg";
      console.log(error.response?.body || error);
      SET_ERROR(error, { messageText });
      return Promise.reject();
    }
  },
  async retrieveIssue({ commit }, { condominiumId, issueId }) {
    try {
      const response = await services.IssueService.retrieveIssue(
        condominiumId,
        issueId
      );
      commit("SET_CURRENT_ISSUE", response.issue);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async retrieveAvailableMaintainers({ commit }, parameters) {
    try {
      const response = await services.MaintainerService.retrieveAdminMaintainers(
        { type: parameters.type }
      );

      let availableMaintainers = [];
      if (parameters.is1Board) {
        availableMaintainers = response.filter(maintainer => {
          return maintainer.type.findIndex(i => i._id === parameters.type) > -1;
        });
      } else {
        availableMaintainers = response.filter(maintainer => {
          return maintainer.type.includes(parameters.type);
        });
      }

      commit("SET_AVAILABLE_MAINTAINERS", availableMaintainers);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createIssue({ commit }, { condominiumId, issueDetails }) {
    try {
      await services.IssueService.createIssue(condominiumId, issueDetails);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async updateIssue({ commit }, { condominiumId, issueId, issueDetails }) {
    try {
      await services.IssueService.updateIssue(
        condominiumId,
        issueId,
        issueDetails
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async addPhotos({ commit }, { condominiumId, issueId, photos }) {
    try {
      await services.IssueService.addPhotos(condominiumId, issueId, photos);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async deletePhoto({ commit }, { condominiumId, issueId, photoId }) {
    try {
      await services.IssueService.deletePhoto(condominiumId, issueId, photoId);
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async createComment({ commit }, { condominiumId, issueId, issueInfo }) {
    try {
      await services.IssueService.createComment(
        condominiumId,
        issueId,
        issueInfo
      );
    } catch (error) {
      console.log(error.response?.body || error);
      SET_ERROR(error, {});
      return Promise.reject();
    }
  },
  async removeIssueReport({ commit }, { condominiumId, issueId }) {
    try {
      await services.IssueService.removeIssueReport(condominiumId, issueId);
    } catch (error) {
      SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  },
  async removeReportIssueComment(
    { commit },
    { condominiumId, issueId, commentId }
  ) {
    try {
      await services.IssueService.removeReportIssueComment(
        condominiumId,
        issueId,
        commentId
      );
    } catch (error) {
      SET_ERROR(error, {});
      return Promise.reject(error.response?.body || error);
    }
  }
};

export default {
  namespaced: true,
  strict: true,
  state,
  getters,
  mutations,
  actions
};
